import React from "react";
import { Container, Flex } from "@chakra-ui/react";
import PortfolioNewsItem from "./PortfolioNewsItem";
import { SectionHeading } from "../shared";

const PortfolioNews = ({ data }) => {
  return (
    <Container maxW="full" centerContent py={[12, 16, 24]}>
      <Flex maxW="6xl" w="full" justifyContent="center" flexWrap="wrap">
        <SectionHeading textAlign={["left", "left", "center"]} pb={[6, 8]}>
          {data.title}
        </SectionHeading>
        <Flex flexWrap="wrap" justifyContent="space-between">
          {data.newsItems.map((item, key) => (
            <PortfolioNewsItem key={key} data={item} />
          ))}
        </Flex>
      </Flex>
    </Container>
  );
};

export default PortfolioNews;
