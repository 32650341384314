import React from "react";
import { Flex, Text, Image, Container, Center } from "@chakra-ui/react";
import { ButtonBig } from "../shared";
import HeroBanner from "./HeroBanner";
import HeroHeading from "./HeroHeading";
import heroBg from "../../../static/hero-bg.svg";

const Hero = ({ data }) => {
  return (
    <>
      <Container
        maxW="full"
        centerContent
        bgColor="brand.100"
        pt={[24, 40]}
        pb={[8, 28]}
        bgImg={["none", "none", "none", "none", `url(${heroBg})`]}
        bgRepeat="no-repeat"
        bgPos={[
          "initial",
          "initial",
          "initial",
          "initial",
          "-4rem 1rem",
          "left 1rem",
        ]}
      >
        <Flex flexWrap={["wrap", "wrap", "nowrap"]} maxW="6xl" w="full">
          <Flex
            flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
            alignItems="center"
            alignContent="center"
            flexWrap="wrap"
            px={[8, 8, 0]}
            pb={[12, 14, 0]}
            textAlign={["center", "center", "left"]}
            justifyContent={["center", "center", "flex-start"]}
          >
            <HeroHeading>{data.title}</HeroHeading>
            <Text
              color="brand.700"
              fontSize={["sm", "md", "md"]}
              pb={[5, 6, 8]}
              fontWeight="300"
            >
              {data.paragraph.paragraph}
            </Text>
            <ButtonBig title={data.buttonText} url={data.buttonUrl} />
          </Flex>
          <Center flex={["1 1 100%", "1 1 100%", "1 1 50%"]} px={[8, 8, 8, 0]}>
            <Image
              src={data.image.file.url}
              maxW="26.5rem"
              w="full"
              h="auto"
              alt={data.image.title}
            />
          </Center>
        </Flex>
      </Container>
      <HeroBanner bannerLogos={data.bannerLogos} />
    </>
  );
};

export default Hero;
