import React from "react";
import { AspectRatio, Box, Image, Link, Text, Flex } from "@chakra-ui/react";
import { ItemHeading, Twitter, LinkedIn } from "../shared";

const TeamMember = ({ data }) => {
  return (
    <Box
      borderTopRadius="lg"
      flex={[
        "1 0 100%",
        "1 0 100%",
        "0 0 calc(100% / 3 - 1.5rem)",
        "0 0 calc(100% / 3 - 3rem)",
      ]}
      pb={[7, 7, 0]}
    >
      <AspectRatio borderRadius="lg" maxW="full" ratio={4 / 3}>
        <Image
          src={data.image.file.url}
          alt={data.name}
          objectFit="cover"
          objectPosition="center"
          borderRadius="lg"
        />
      </AspectRatio>
      <ItemHeading pt={[4, 5]} pb="1">
        {data.name}
      </ItemHeading>
      <Text textColor="brand.100" fontSize={["sm", "md"]} fontWeight="300">
        {data.shortBio.shortBio}
      </Text>
      <Flex pt="3">
        {data.twitterUrl && (
          <Link href={data.twitterUrl} isExternal h="1.25rem" w="auto" mr="2">
            <Twitter />
          </Link>
        )}

        {data.linkedInUrl && (
          <Link href={data.linkedInUrl} isExternal h="1.25rem" w="auto">
            <LinkedIn />
          </Link>
        )}
      </Flex>
    </Box>
  );
};

export default TeamMember;
