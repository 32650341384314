import React from "react";
import { Container, Flex, Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { SectionHeading, TextLinkWithArrow } from "../shared";
import InvestItem from "./InvestItem";

const Invest = ({ data }) => {
  return (
    <Container id="invest" maxW="full" centerContent pb={[12, 14, 20]}>
      <Flex maxW="6xl" w="full" justifyContent="center" flexWrap="wrap">
        <SectionHeading textAlign={["left", "left", "center"]} pb={[2, 3, 12]}>
          {data.title}
        </SectionHeading>
        <Flex flexWrap="wrap">
          <Box
            textColor="brand.100"
            fontWeight="300"
            fontSize={["sm", "md"]}
            flex={["1 0 100%", "1 0 100%", "0 0 calc(100% / 3 * 2)"]}
            pr={[0, 0, 16]}
            pb={[8, 8, 0]}
          >
            <TextContainer
              dangerouslySetInnerHTML={{
                __html: data.text.childrenMarkdownRemark[0].html,
              }}
            />
            <TextLinkWithArrow linkType="External" linkUrl={data.linkUrl}>
              {data.linkText}
            </TextLinkWithArrow>
          </Box>
          <Box
            bgColor="brand.600"
            px="10"
            py="7"
            borderRadius="lg"
            flex={["1 0 100%", "1 0 100%", "0 0 calc(100% / 3)"]}
          >
            {data.investItems.map((item, key) => (
              <InvestItem key={key} data={item} />
            ))}
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
};

const TextContainer = styled.div`
  & p {
    padding-bottom: 1em;
  }
`;

export default Invest;
