import React from "react";
import { Box, Image, Text, Flex } from "@chakra-ui/react";
import { ItemHeading, TextLinkWithArrow } from "../shared";

const WhatWeDoItem = ({ data }) => {
  return (
    <Flex
      flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
      alignItems="flex-start"
      flexWrap={["wrap", "nowrap"]}
      pt={[10, 14]}
      pr={[0, 4, 12]}
    >
      <Image
        src={data.icon.file.url}
        alt={data.icon.title}
        flex={["0 0 2.5rem", "0 0 3.125rem"]}
      />
      <Box
        flex={["1 1 100%", "1 1 calc(100% - 3.125rem)"]}
        pt={[4, 0]}
        pl={[0, 4]}
      >
        <ItemHeading>{data.title}</ItemHeading>
        <Text
          textColor="brand.100"
          fontSize={["sm", "md"]}
          pt={[1, 2]}
          fontWeight="300"
          pb={[2, 3]}
        >
          {data.paragraph.paragraph}
        </Text>

        <TextLinkWithArrow linkType={data.linkType} linkUrl={data.linkUrl}>
          {data.linkText}
        </TextLinkWithArrow>
      </Box>
    </Flex>
  );
};

export default WhatWeDoItem;
