import React from "react";
import { Container, Flex, Text } from "@chakra-ui/react";
import { SectionHeading } from "../shared";
import TeamMember from "./TeamMember";

const Team = ({ data }) => {
  return (
    <Container maxW="full" centerContent pb={[12, 14, 20]}>
      <Flex maxW="6xl" w="full" justifyContent="center" flexWrap="wrap">
        <SectionHeading textAlign={["left", "left", "center"]}>
          {data.title}
        </SectionHeading>
        <Text
          maxW="2xl"
          w="full"
          textAlign={["left", "left", "center"]}
          textColor="brand.100"
          fontSize={["sm", "md"]}
          fontWeight="300"
          pb={[8, 10]}
        >
          {data.paragraph.paragraph}
        </Text>
        <Flex flexWrap="wrap" justifyContent="space-between" px={[0, 10, 0]}>
          {data.teamMembers.map((item, key) => (
            <TeamMember data={item} key={key} />
          ))}
        </Flex>
      </Flex>
    </Container>
  );
};

export default Team;
