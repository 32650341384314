import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'

import { Layout, Head, Cta, Contact } from '../components/shared'
import { Hero, WhatWeDo, AboutUs, PortfolioCompanies, Invest, Team, PortfolioNews } from '../components/Home'

const RootIndex = ({ data, location }) => {
  const allData = data.allContentfulHome.edges[0].node
  const contactSection = useRef()

  useEffect(() => {
    location.search === '?contact' && contactSection.current.scrollIntoView({ behavior: 'smooth' })
  }, [location])

  return (
    <Layout location={location}>
      <Head data={allData.seo} />
      <Hero data={allData.hero} />
      <WhatWeDo data={allData.whatWeDo} />
      <AboutUs data={allData.aboutUs} />
      <PortfolioCompanies data={allData.portfolioCompanies} />
      <PortfolioCompanies data={allData.portfolioCompanies2} />
      <Invest data={allData.invest} />
      <Team data={allData.team} />
      <Cta data={allData.cta} />
      <PortfolioNews data={allData.portfolioNews} />
      <Contact data={allData.contact} ref={contactSection} />
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allContentfulHome {
      edges {
        node {
          hero {
            title
            paragraph {
              paragraph
            }
            buttonText
            buttonUrl
            image {
              title
              file {
                url
              }
            }
            bannerLogos {
              file {
                url
              }
              title
            }
          }
          whatWeDo {
            title
            paragraph {
              paragraph
            }
            itemBlocks {
              title
              paragraph {
                paragraph
              }
              linkUrl
              linkType
              linkText
              icon {
                file {
                  url
                }
                title
              }
            }
          }
          aboutUs {
            paragraph {
              paragraph
            }
            title
            image {
              file {
                url
              }
              title
            }
          }
          portfolioCompanies {
            title
            paragraph {
              paragraph
            }
            items {
              title
              paragraph {
                paragraph
              }
              image {
                file {
                  url
                }
              }
            }
          }
          portfolioCompanies2 {
            title
            paragraph {
              paragraph
            }
            items {
              title
              paragraph {
                paragraph
              }
              image {
                file {
                  url
                }
              }
            }
          }
          invest {
            title
            text {
              childrenMarkdownRemark {
                html
              }
            }
            linkText
            linkUrl
            investItems {
              text
              title
              icon {
                file {
                  url
                }
              }
            }
            text {
              childrenMarkdownRemark {
                html
              }
            }
          }
          team {
            title
            paragraph {
              paragraph
            }
            teamMembers {
              name
              shortBio {
                shortBio
              }
              linkedInUrl
              image {
                file {
                  url
                }
              }
            }
          }
          cta {
            title {
              childMarkdownRemark {
                html
              }
            }
            linkUrl
            linkText
          }
          portfolioNews {
            title
            newsItems {
              title
              paragraph {
                paragraph
              }
              linkUrl
              linkText
            }
          }
          contact {
            title
            text {
              childrenMarkdownRemark {
                html
              }
            }
            eMail
            image {
              file {
                url
              }
            }
          }
          seo {
            pageTitle
            metaDescription {
              metaDescription
            }
            metaImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default RootIndex
