import React from "react";
import { Container, Flex, Image } from "@chakra-ui/react";

const HeroBanner = ({ bannerLogos }) => {
  return (
    <Container bgColor="brand.600" maxW="full" centerContent py={[2, 2, 8]}>
      <Flex
        maxW="6xl"
        w="full"
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems="center"
      >
        {bannerLogos.map((item, key) => (
          <Image
            src={item.file.url}
            alt={item.title}
            key={key}
            width={[
              "calc(100% / 3 - 1.25rem)",
              "calc(100% / 3 - 2rem)",
              "calc(100% / 6 - 2rem)",
            ]}
            my={[3, 3, 0]}
          />
        ))}
      </Flex>
    </Container>
  );
};

export default HeroBanner;
