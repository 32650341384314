import * as React from 'react'
import { Box, Container, Flex, Text } from '@chakra-ui/react'
import PortfolioCompaniesItem from './PortfolioCompaniesItem'
import { SectionHeading } from '../shared'

const PortfolioCompanies = ({ data }) => {
  const { items } = data

  return (
    <Container maxW="full" centerContent py={[12, 14, 20]}>
      <Flex maxW="6xl" w="full" justifyContent="center" flexWrap="wrap">
        <Box flex={['1 0 100%', null, null, '1 0 calc(100% / 3)']} pr={[0, null, 6, 12]} pb={[6, null, 12, 0]}>
          <SectionHeading>{data.title}</SectionHeading>
          <Text w="full" textColor="brand.100" fontSize={['sm', 'md']} fontWeight="300">
            {data.paragraph.paragraph}
          </Text>
        </Box>
        <Flex
          flex={['1 0 100%', null, null, '1 0 calc(100% / 3 * 2)']}
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="space-between"
          pt="2"
        >
          {items.map((item, key) => (
            <PortfolioCompaniesItem data={item} key={key} />
          ))}
        </Flex>
      </Flex>
    </Container>
  )
}

export default PortfolioCompanies
