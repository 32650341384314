import React from "react";
import { Box, Flex, Image, Text, Heading } from "@chakra-ui/react";

const InvestItem = ({ data }) => {
  return (
    <Flex py="3">
      <Image
        src={data.icon.file.url}
        alt={data.title}
        w="10"
        h="auto"
        flex="0 0 2.5rem"
      />
      <Box flex="0 0 calc(100% - 2.5rem)" pl="4">
        <Heading
          as="h3"
          fontSize="lg"
          fontWeight="600"
          textColor="brand.100"
          fontFamily="Montserrat, sans-serif"
          pb="1"
        >
          {data.title}
        </Heading>
        <Text
          textColor="brand.100"
          fontWeight="300"
          fontSize={["sm", "md"]}
          w="full"
        >
          {data.text}
        </Text>
      </Box>
    </Flex>
  );
};

export default InvestItem;
