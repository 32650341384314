import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";

const PortfolioCompaniesItem = ({ data }) => {
  return (
    <Box
      flex={[
        "1 0 100%",
        "1 0 100%",
        "0 0 calc(50% - 1rem)",
        "0 0 calc(50% - 2rem)",
      ]}
      pb={[6, 6, 12]}
    >
      <Image src={data.image.file.url} alt={data.title} maxW="24" maxH="6" />
      <Text
        w="full"
        textColor="brand.100"
        fontSize={["sm", "md"]}
        fontWeight="300"
        pt={[2, 2, 3]}
      >
        {data.paragraph.paragraph}
      </Text>
    </Box>
  );
};

export default PortfolioCompaniesItem;
