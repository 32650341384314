import React from "react";
import { Container, Flex, Text } from "@chakra-ui/react";
import { SectionHeading } from "../shared";
import WhatWeDoItem from "./WhatWeDoItem";
import whatWeDoBg from "../../../static/what-we-do-bg.svg";

const WhatWeDo = ({ data }) => {
  return (
    <Container
      maxW="full"
      centerContent
      bgImg={["none", "none", "none", "none", `url(${whatWeDoBg})`]}
      bgRepeat="no-repeat"
      py={[12, 14, 20]}
      bgPos={[
        "initial",
        "initial",
        "initial",
        "initial",
        "right -4rem top 1rem",
        "right 1rem",
      ]}
    >
      <Flex maxW="6xl" w="full" justifyContent="center" flexWrap="wrap">
        <SectionHeading textAlign={["left", "left", "center"]}>
          {data.title}
        </SectionHeading>
        <Text
          maxW="2xl"
          w="full"
          textAlign={["left", "left", "center"]}
          textColor="brand.100"
          fontSize={["sm", "md"]}
          fontWeight="300"
        >
          {data.paragraph.paragraph}
        </Text>
        <Flex flexWrap="wrap">
          {data.itemBlocks.map((item, key) => (
            <WhatWeDoItem data={item} key={key} />
          ))}
        </Flex>
      </Flex>
    </Container>
  );
};

export default WhatWeDo;
