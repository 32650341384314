import React from "react";
import { Center, Container, Flex, Image, Text } from "@chakra-ui/react";
import { SectionHeading } from "../shared";

const AboutUs = ({ data }) => {
  return (
    <Container maxW="full" centerContent py={[12, 14, 20]} bgColor="brand.600">
      <Flex maxW="6xl" w="full" justifyContent="center" flexWrap="wrap">
        <Flex
          flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
          order={[0, 0, 1]}
          flexWrap="wrap"
          alignItems="center"
          alignContent="center"
          pb={[8, 8, 0]}
        >
          <SectionHeading textAlign={["center", "center", "left"]}>
            {data.title}
          </SectionHeading>
          <Text
            w="full"
            textAlign={["center", "center", "left"]}
            textColor="brand.100"
            fontSize={["sm", "md"]}
            fontWeight="300"
          >
            {data.paragraph.paragraph}
          </Text>
        </Flex>
        <Center
          flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
          px={[8, 8, 8, 0]}
          order={[1, 1, 0]}
        >
          <Image
            src={data.image.file.url}
            maxW="26.5rem"
            w="full"
            h="auto"
            alt={data.image.title}
          />
        </Center>
      </Flex>
    </Container>
  );
};

export default AboutUs;
