import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { ItemHeading, TextLinkWithArrow } from "../shared";

const PortfolioNewsItem = ({ data }) => {
  return (
    <Box
      flex={[
        "0 0 100%",
        "0 0 calc(50% - 2rem)",
        "0 0 calc(50% - 3rem)",
        "0 0 calc(25% - 3rem)",
      ]}
      pb={[6, 6, 6, 0]}
    >
      <ItemHeading pb={[1, 2]}>{data.title}</ItemHeading>
      <Text
        textColor="brand.100"
        fontSize={["sm", "md"]}
        fontWeight="300"
        pb={[2]}
      >
        {data.paragraph.paragraph}
      </Text>
      <TextLinkWithArrow linkUrl={data.linkUrl} linkType="External">
        {data.linkText}
      </TextLinkWithArrow>
    </Box>
  );
};

export default PortfolioNewsItem;
