import React from "react";
import { Heading } from "@chakra-ui/react";

const HeroHeading = ({ children }) => {
  return (
    <Heading
      as="h1"
      fontSize={["3xl", "4xl", "5xl"]}
      lineHeight="shorter"
      color="brand.700"
      pb={[4, 5, 6]}
      fontFamily="Montserrat, sans-serif"
    >
      {children}
    </Heading>
  );
};

export default HeroHeading;
